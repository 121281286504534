import './wishlist.css';
import { useParams } from 'react-router-dom';
import {useState, useEffect} from 'react';
import PageNotFound from './pageNotFound';

const Closet = () => {
  const { username } = useParams();

  useEffect(() => {
    document.title = "Spree | " + username + "'s Closet";
  }, []);

  const [pageNotFound, setPageNotFound] = useState(false);

  const [data, setData] = useState(null);
  const loadData = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL+"/v2/web/users/" + username + "/closet/", {
      method: 'GET',
    });
    if (response.ok) {
      const json = await response.json();
      const loadedTags = json.map(function(elt) {
        return {
          category: elt.category,
          brandName: elt.brandName,
          url: elt.url,
          image_src: elt.photoEndpoint,  
          color: elt.color,
          name: elt.name,
          price: elt.price,
        };
      });
      setData(loadedTags);
    } else {
      setPageNotFound(true);
      document.title = "Spree | Page Not Found";
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  const getDescriptiveName = (category, brandName, color) => {
    let descriptiveName = category;
    if (brandName != "") {
      descriptiveName = brandName + " " + descriptiveName;
    }   
    if (color != "") {
      descriptiveName = color + " " + descriptiveName;
    }   
    return descriptiveName;
  };  
  const getTitle = (name, category, brandName, color) => {
    if (name != "") {
      return name;
    } else {
      return getDescriptiveName(category, brandName, color);
    }   
  };  
  const getSubtitle = (name, category, brandName, color) => {
    if (name != "") {
      return getDescriptiveName(category, brandName, color);
    } else {
      return ""; 
    }   
  };

  return (
    <div className="WishlistContainerVertical" >
      <div className="WishlistHeader" >
      <div className="WishlistHeaderMaxWidth" >
        <div className="WishlistLogo" >
          {"spree"}
        </div>
        <a href="https://apps.apple.com/us/app/spree-a-fashion-community/id1616281339" target="_blank" >
        <img className="WishlistAppButton" src={require("./images/download-app-store.svg").default}  />
        </a>
      </div>
      </div>
      <div className="WishlistContainerHorizontal" >
      <div className="WishlistContainerMaxWidth" >
      {(pageNotFound == true)
      ? (
        <PageNotFound />
      ) : (
      <>
        <div className="WishlistTitle" >
          {username + "'s Closet"}
        </div>
        <div className="WishlistItemCount" >
          {(data == null) ? "0 items" : data.length + " items"}
        </div>
        <div className="WishlistItemGrid" >
          {(data != null) && data.map((item, i) =>
            <a className="WishlistItem" href={item.url} target="_blank" rel="noopener noreferrer" >
              <img src={item.image_src} className="WishlistImage" />
              <div className="WishlistCaption" >
                <div className="WishlistItemTitle" >
                  {getTitle(item.name, item.category, item.brandName, item.color)}
                </div>
                {(getSubtitle(item.name, item.category, item.brandName, item.color) != "") &&
                  <div className="WishlistItemSubtitle" >
                    {getSubtitle(item.name, item.category, item.brandName, item.color)}
                  </div>}
                {(item.price != null) &&
                  <div className="WishlistItemSubtitle" >
                    {"$" + item.price}
                  </div>}
              </div>
            </a>)}
        </div>
      </>)}
      </div>
      </div>
    </div>
  );
};

export default Closet;
