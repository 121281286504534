import './wishlist.css';
import './dashboard.css';
import './rules.css';
import {useEffect, useState} from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import Select, { components } from 'react-select';


const Rules = () => {
  const { brandId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    document.title = "Spree Dashboard";
    window.history.replaceState({}, "");
  }, []);

  const [catalogSections, setCatalogSections] = useState([]);
  const [rules, setRules] = useState([]);

  const loadRules = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL+"/v2/dashboard/" + brandId + "/rules/", {
      method: 'GET',
      credentials: 'include',
    });
    if (response.ok) {
      const json = await response.json();
      setCatalogSections(json.sections);
      const loadedRules = json.rules.map(function(elt) {
        return {
          id: elt.id,
          section: elt.section,
          on: elt.on,
          state: elt.state,
          segment: getTextFromFilters(elt.state.ages, elt.state.sizes, elt.state.hairs, elt.state.skintones, elt.state.locs, elt.state.gender)
        };
      });
      setRules(loadedRules);
    } else if (response.status == 403) {
      navigate("/dashboard/" + brandId + "/login/");
    }
  };
  useEffect(() => {
    loadRules();
  }, []);

  const getTextFromOptions = (options) => {
    options = options.filter(opt => !opt.value.includes(":All"));
    const ages = options.filter(opt => opt.value.startsWith("age:")).map(opt => parseInt(opt.value.split(":", 2)[1]));
    const sizes = options.filter(opt => opt.value.startsWith("size:")).map(opt => opt.value.split(":", 2)[1]);
    const hairs = options.filter(opt => opt.value.startsWith("hair:")).map(opt => opt.value.split(":", 2)[1]);
    const skintones = options.filter(opt => opt.value.startsWith("skintone:")).map(opt => opt.value.split(":", 2)[1]);
    const locs = options.filter(opt => opt.value.startsWith("loc:")).map(opt => opt.value.split(":", 2)[1]);
    let gender = null;
    if (options.filter(opt => opt.value == "gender:Female").length == 1) {
      gender = "Female";
    } else if (options.filter(opt => opt.value == "gender:Male").length == 1) {
      gender = "Male";
    }
    return getTextFromFilters(ages, sizes, hairs, skintones, locs, gender);
  };

  const sizeToInt = (size) => {
    const sizes = ["XS", "S", "M", "L", "XL"];
    return sizes.indexOf(size);
  };
  // matches getDrilldownTitle in analytics.js
  const getTextFromFilters = (ages, sizes, hairs, skintones, locs, gender) => {
    let title = "";
    if (gender == "Female") {
      title = title + "Women";
    } else if (gender == "Male") {
      title = title + "Men";
    } else {
      title = title + "Customers";
    }
    if (ages.length > 0) {
      title = title + " aged " + ages.toSorted().join(", ");
    }
    if (sizes.length > 0) {
      title = title + ", size " + sizes.toSorted((a, b) => (sizeToInt(a)-sizeToInt(b))).join(", ");
    }
    if (hairs.length > 0) {
      title = title + " with " + hairs.join(", ") + " hair";
    }
    if (skintones.length > 0) {
      if (hairs.length > 0) {
        title = title + " and a " + skintones.join(", ") + " skin tone";
      } else {
        title = title + " with a " + skintones.join(", ") + " skin tone";
      }
    }
    if (locs.length > 0) {
      title = title + " from " + locs.join(", ");
    }
    if (title == "Customers") {
      title = "All Customers";
    }
    return title;
  };

  const getCatalogSectionOptions = () => {
    let options = [{label: "+ Create New", value: null}];
    options.push(...catalogSections.map(section => ({label: section.name, value: section.id})));
    return options;
  };
  const segmentOptions = [{
    label: "Hair Color",
    options: ["All Hair Colors", "Blonde", "Red", "Brown", "Black", "Gray"].map(item => ({value: "hair:" + item, label: item}))
  }, {
    label: "Skin Tone",
    options: ["All Skin Tones", "Pale", "Fair", "Tan", "Brown", "Black"].map(item => ({value: "skintone:" + item, label: item}))
  }, {
    label: "Size",
    options: ["All Sizes", "XS", "S", "M", "L", "XL"].map(item => ({value: "size:" + item, label: item}))
  }, {
    label: "Gender",
    options: ["All Genders", "Female", "Male"].map(item => ({value: "gender:" + item, label: item}))
  }, {
    label: "Age",
    options: ["All Ages", 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].map(item => ({value: "age:" + item, label: item}))
  }, {
    label: "Location",
    options: ["All Locations", "New York City", "Los Angeles", "Chicago", "Dallas", "Houston", "Washington, D.C.", "Philadelphia", "Atlanta", "Miami", "Detroit", "San Diego", "Phoenix", "Boston", "San Francisco", "Seattle", "Minneapolis", "Tampa", "Denver", "Baltimore", "St. Louis", "Orlando", "Charlotte", "San Antonio", "Portland, OR", "Pittsburgh", "Austin", "Sacramento", "El Paso", "Las Vegas", "Cincinnati", "Kansas City", "Columbus", "Cleveland", "Indianapolis", "Nashville"].map(item => ({value: "loc:" + item, label: item}))
  }];
  const initialSelectedSegmentOptions = [
    {value: "hair:All Hair Colors", label: "All Hair Colors"},
    {value: "skintone:All Skin Tones", label: "All Skin Tones"},
    {value: "size:All Sizes", label: "All Sizes"},
    {value: "gender:All Genders", label: "All Genders"},
    {value: "age:All Ages", label: "All Ages"},
    {value: "loc:All Locations", label: "All Locations"}
  ];
  const getOptionsFromState = (filters) => {
    let options = [];
    if (filters.hairs.length > 0) {
      for (let hair of filters.hairs) {
        options.push({label: hair, value: "hair:" + hair});
      }
    } else {
      options.push(segmentOptions.flatMap(group => group.options).filter(opt => opt.value.startsWith("hair:All"))[0]);
    }
    if (filters.ages.length > 0) {
      for (let age of filters.ages) {
        options.push({label: age, value: "age:" + age});
      }
    } else {
      options.push(segmentOptions.flatMap(group => group.options).filter(opt => opt.value.startsWith("age:All"))[0]);
    }
    if (filters.skintones.length > 0) {
      for (let skintone of filters.skintones) {
        options.push({label: skintone, value: "skintone:" + skintone});
      }
    } else {
      options.push(segmentOptions.flatMap(group => group.options).filter(opt => opt.value.startsWith("skintone:All"))[0]);
    }
    if (filters.locs.length > 0) {
      for (let loc of filters.locs) {
        options.push({label: loc, value: "loc:" + loc});
      }
    } else {
      options.push(segmentOptions.flatMap(group => group.options).filter(opt => opt.value.startsWith("loc:All"))[0]);
    }
    if (filters.sizes.length > 0) {
      for (let size of filters.sizes) {
        options.push({label: size, value: "size:" + size});
      }
    } else {
      options.push(segmentOptions.flatMap(group => group.options).filter(opt => opt.value.startsWith("size:All"))[0]);
    }
    if (filters.gender != null) {
      options.push({label: filters.gender, value: "gender:" + filters.gender});
    } else {
      options.push(segmentOptions.flatMap(group => group.options).filter(opt => opt.value.startsWith("gender:All"))[0]);
    }
    return options;
  };
  const [selectedSegmentOptions, setSelectedSegmentOptions] = useState((state == null) ? initialSelectedSegmentOptions : getOptionsFromState(state));

  const [newRuleSegmentText, setNewRuleSegmentText] = useState((state == null) ? "All Customers" : getTextFromFilters(state.ages, state.sizes, state.hairs, state.skintones, state.locs, state.gender));
  const [newRuleSection, setNewRuleSection] = useState(null);

  const handleSegmentChange = (newOptions) => {
    const addedOptions = newOptions.filter(opt => !selectedSegmentOptions.includes(opt));
    const removedOptions = selectedSegmentOptions.filter(opt => !newOptions.includes(opt));
    for (let addedOption of addedOptions) {
      if (addedOption.value.startsWith("gender:")) {
        newOptions = newOptions.filter(opt => !opt.value.startsWith("gender:") || (opt == addedOption));
      } else if (addedOption.value.includes(":All")) {
        newOptions = newOptions.filter(opt => !opt.value.startsWith(addedOption.value.split(":", 1)[0] + ":") || (opt == addedOption));
      } else {
        newOptions = newOptions.filter(opt => !opt.value.startsWith(addedOption.value.split(":", 1)[0] + ":All"));
      }
    }
    for (let removedOption of removedOptions) {
      if (newOptions.filter(opt => opt.value.startsWith(removedOption.value.split(":", 1)[0])).length == 0) {
        newOptions.push(segmentOptions.flatMap(group => group.options).filter(opt => opt.value.startsWith(removedOption.value.split(":", 1)[0] + ":All"))[0]);
      }
    }
    setSelectedSegmentOptions(newOptions);
    setNewRuleSegmentText(getTextFromOptions(newOptions));
  };

  const onSectionChange = (option) => {
    if (option == null) {
      setNewRuleSection(null);
    } else {
      setNewRuleSection({name: option.label, id: option.value});
    }
  };

  const getCookie = (name) => {
    if (document.cookie && document.cookie != "") {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) == name + "=") {
          return decodeURIComponent(cookie.substring(name.length + 1));
        }
      }
    }
    return null;
  };

  const [createNew, setCreateNew] = useState(true);
  const cancelNewRule = () => {
    setCreateNew(false);
    setSelectedSegmentOptions(initialSelectedSegmentOptions);
    setNewRuleSegmentText("All Customers");
    setNewRuleSection(null);
  };
  const canSave = () => {
    return (newRuleSection != null);
  };
  const saveNewRule = async () => {
    if (!canSave()) {
      return;
    }
    const filtersState = {
      ages: selectedSegmentOptions.filter(opt => opt.value.startsWith("age:") && !opt.value.includes(":All")).map(opt => parseInt(opt.value.split(":", 2)[1])),
      hairs: selectedSegmentOptions.filter(opt => opt.value.startsWith("hair:") && !opt.value.includes(":All")).map(opt => opt.value.split(":", 2)[1]),
      sizes: selectedSegmentOptions.filter(opt => opt.value.startsWith("size:") && !opt.value.includes(":All")).map(opt => opt.value.split(":", 2)[1]),
      locs: selectedSegmentOptions.filter(opt => opt.value.startsWith("loc:") && !opt.value.includes(":All")).map(opt => opt.value.split(":", 2)[1]),
      skintones: selectedSegmentOptions.filter(opt => opt.value.startsWith("skintone:") && !opt.value.includes(":All")).map(opt => opt.value.split(":", 2)[1]),
      gender: (selectedSegmentOptions.filter(opt => opt.value == "gender:Female").length > 0) ? "Female" : (selectedSegmentOptions.filter(opt => opt.value == "gender:Male").length > 0) ? "Male" : null
    };

    const csrftoken = getCookie('csrftoken');
    const response = await fetch(process.env.REACT_APP_API_URL+"/v2/dashboard/" + brandId + "/rules/save/", {
      method: 'POST',
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify({segment: filtersState, section: newRuleSection.id})
    });
    if (response.ok) {
      const json = await response.json();
      let newRules = [...rules];
      newRules.push({id: json.id, segment: newRuleSegmentText, section: newRuleSection.name, on: true, state: filtersState});
      setRules(newRules);
      cancelNewRule();
    }
  };

  const goToCustomers = (state) => {
    navigate("/dashboard/" + brandId + "/customers/", {state: state});
  };
  const goToProducts = (name) => {
    navigate("/dashboard/" + brandId + "/products/", {state: {chosenAttribute: "Custom: " + name}});
  };

  const toggleOn = (ruleId, evt) => {
    const csrftoken = getCookie('csrftoken');
    fetch(process.env.REACT_APP_API_URL+"/v2/dashboard/" + brandId + "/rules/" + ruleId + "/enabled/", {
      method: 'POST',
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify({enabled: evt.target.checked})
    });
  };

  return (
    <div className="WishlistContainerVertical" >
      <div className="WishlistHeader" >
      <div className="WishlistHeaderMaxWidth" >
        <div className="WishlistLogo" >
          {"spree"}
        </div>
        <Link className="DashboardHeaderButton" to={"../analytics/"} relative="path" >
          {"Analytics"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../customers/"} relative="path" >
          {"Customers"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../products/"} relative="path" >
          {"Products"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../rules/"} relative="path" >
          {"Rules"}
        </Link>
      </div>
      </div>
      <div className="WishlistContainerHorizontal" >
        <div className="WishlistContainerMaxWidth" >
          <div className="RulesTitleRow" >
            <div className="RulesTitle" >
              {"Custom Rules"}
            </div>
            {!createNew &&
              <div className="RulesCreateNewButton" onClick={() => setCreateNew(true)} >
                {"+ New"}
              </div>}
          </div>
          {createNew &&
            <div className="RulesNew" >
              <div className="RulesNewTitle" >
                {"Create a New Rule"}
              </div>
              <div className="RulesNewBody" >
                <div className="RulesNewCustomerSegment" >
                  <div className="RulesNewCustomerSegmentTitle" >
                    {"Pick a Customer Segment"}
                  </div>
                  <Select
                    options={segmentOptions}
                    value={selectedSegmentOptions}
                    onChange={handleSegmentChange}
                    placeholder={false}
                    isSearchable={false}
                    isMulti={true}
                    isClearable={true}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    controlShouldRenderValue={false}
                    selectProps={{displayText: newRuleSegmentText}}
                    components={{Option: CheckboxOption, ValueContainer: CustomerSegmentValueContainer}}
                  />
                </div>
                <div className="RulesNewSection" >
                  <div className="RulesNewCustomerSegmentTitle" >
                    {"Pick Products"}
                  </div>
                  <Select
                    options={getCatalogSectionOptions()}
                    isSearchable={false}
                    isClearable={true}
                    onChange={onSectionChange}
                    selectProps={{brandId: brandId}}
                    components={{Option: LinkOption}}
                  />
                </div>
                <div className="RulesNewButtonsContainer" >
                <div className="RulesNewButtons" >
                  <div className={canSave() ? "RulesNewSave" : "RulesNewSaveDisabled"} onClick={saveNewRule} >
                    {"Save"}
                  </div>
                  <div className="RulesNewCancel" onClick={cancelNewRule} >
                    {"Cancel"}
                  </div>
                </div>
                </div>
              </div>
            </div>}
          <div className="RulesAll" >
            <div className="RulesAllHeader" >
              <div className="RulesItemColumns" >
                <div className="RulesSegmentHeader" >
                  {"Customer Segment"}
                </div>
                <div className="RulesSectionHeader" >
                  {"Products"}
                </div>
                <div className="RulesCheckboxHeader" >
                  {"On"}
                </div>
              </div>
            </div>
            {rules.toReversed().map(rule => (
              <div key={rule.id} className="RulesItem" >
                <div className="RulesItemColumns" >
                  <div className="RulesSegment" >
                    <div className="RulesSegmentText" onClick={() => goToCustomers(rule.state)} >
                      {rule.segment}
                    </div>
                  </div>
                  <div className="RulesSection" >
                    <div className="RulesSegmentText" onClick={() => goToProducts(rule.section)} >
                      {rule.section}
                    </div>
                  </div>
                </div>
                <div className="RulesCheckbox" >
                  <input type="checkbox" defaultChecked={rule.on} onClick={(evt) => toggleOn(rule.id, evt)} />
                </div>
              </div>))}
          </div>
        </div>
      </div>
    </div>
  );
};

const CheckboxOption = (props) => {
  return (
    <components.Option {...props} >
      <div className="RulesCheckboxOption" >
        <input type="checkbox" checked={props.isSelected} />
        <div className="RulesCheckboxOptionText" >
          {props.label}
        </div>
      </div>
    </components.Option>
  );
};

const LinkOption = (props) => {
  const navigate = useNavigate();

  const maybeCreateNew = () => {
    if (props.children == "+ Create New") {
      navigate("/dashboard/" + props.selectProps.selectProps.brandId + "/products/", {state: {newCustom: true}});
    }
  };

  return (
    <components.Option {...props} >
      <div className={(props.children == "+ Create New") ? "RulesLinkOption" : "RulesNormalOption"} onClick={maybeCreateNew} >
        {props.children}
      </div>
    </components.Option>
  );
};

const CustomerSegmentValueContainer = (props) => {
  return (
    <components.ValueContainer {...props} >
      <div className="RulesValueContainer" >
        <div className="RulesHideValueContainerChildren" >
          {props.children}
        </div>
        {(props.selectProps.selectProps.displayText == "All Customers")
        ? (
          <div className="RulesValueContainerDefault" >
            {"All Customers"}
          </div>
        ) : (
          <div className="RulesValueContainerValue" >
            {props.selectProps.selectProps.displayText}
          </div>
        )}
      </div>
    </components.ValueContainer>
  );
};

export default Rules;
