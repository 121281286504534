import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const PageWrapper = ({element}) => {
  const location = useLocation();

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.send({hitType: "pageview", page: location.pathname + location.search});
    }
  }, [location]);

  return element;
};

export default PageWrapper;
