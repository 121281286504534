import './wishlist.css';
import './dashboard.css';
import {useState, useEffect} from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const { brandId, userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Spree Dashboard";
  }, []);

  const [paused, setPaused] = useState(false);

  const [topProducts, setTopProducts] = useState([]);
  const [categoryLikelihoods, setCategoryLikelihoods] = useState({});
  const [priceLikelihoods, setPriceLikelihoods] = useState({});
  const [topPriceRange, setTopPriceRange] = useState("");
  const [topCategories, setTopCategories] = useState([]);
  const [topBrands, setTopBrands] = useState([]);
  const [topCategoryList, setTopCategoryList] = useState("");
  const [daysActive, setDaysActive] = useState("");
  const loadTopProducts = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL+"/v2/dashboard/" + brandId + "/users/" + userId + "/likelihood/", {
      method: 'GET',
      credentials: 'include',
    });
    if (response.ok) {
      const json = await response.json();
      setTopProducts(json.topProducts);
      setCategoryLikelihoods(json.likelihoods.category);
      setPriceLikelihoods(json.likelihoods.price);
      setTopPriceRange(json.behavior.price);
      setTopCategories(json.behavior.category);
      setTopBrands(json.behavior.brand);
      setTopCategoryList(json.behavior.category_list);
      setDaysActive(json.behavior.days_active);
    }
  };

  const [activityLog, setActivityLog] = useState([]);
  const [currentPage, setCurrentPage] = useState("");
  const [viewing, setViewing] = useState([]);
  const loadEvents = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL+"/v2/dashboard/" + brandId + "/users/" + userId + "/activity/", {
      method: 'GET',
      credentials: 'include',
    });
    if (response.ok) {
      const json = await response.json();
      setActivityLog(json.activity_log);
      setCurrentPage(json.current_page);
      setViewing(json.currently_viewing);
    }
  };
  useEffect(() => {
    if (!paused) {
      loadEvents();
      const eventsInterval = setInterval(loadEvents, 500);
      loadTopProducts();
      const topProductsInterval = setInterval(loadTopProducts, 2 * 1000);
      return () => {
        clearInterval(eventsInterval);
        clearInterval(topProductsInterval);
      };
    }
  }, [paused]);

  const [blinkingRedDot, setBlinkingRedDot] = useState(true);
  const toggleBlinkingRedDot = () => {
    setBlinkingRedDot(false);
    setTimeout(() => setBlinkingRedDot(true), 800);
    setTimeout(toggleBlinkingRedDot, 2000);
  };
  useEffect(() => {
    toggleBlinkingRedDot();
  }, []);

  const [username, setUsername] = useState("");
  const [brandName, setBrandName] = useState("");
  const [age, setAge] = useState("");
  const [height, setHeight] = useState("");
  const [gender, setGender] = useState("");
  const [hairColor, setHairColor] = useState("");
  const [skinTone, setSkinTone] = useState("");
  const [bodyType, setBodyType] = useState("");
  const [clothingSize, setClothingSize] = useState("");
  const [shoeSize, setShoeSize] = useState("");
  const [loc, setLoc] = useState("");
  const loadInfo = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL+"/v2/dashboard/" + brandId + "/users/" + userId + "/info/", {
      method: 'GET',
      credentials: 'include',
    });
    if (response.ok) {
      const json = await response.json();
      setUsername(json.username);
      setBrandName(json.brandName);
      setAge(json.age);
      setHeight(json.height);
      setGender(json.gender);
      setHairColor(json.hairColor);
      setSkinTone(json.skinTone);
      setBodyType(json.bodyType);
      setClothingSize(json.clothingSize);
      setShoeSize(json.shoeSize);
      setLoc(json.loc);
    } else if (response.status == 403) {
      navigate("/dashboard/" + brandId + "/login/");
    }
  };
  useEffect(() => {
    loadInfo();
  }, []);

  return (
    <div className="WishlistContainerVertical" >
      <div className="WishlistHeader" >
      <div className="DashboardHeaderMaxWidth" >
        <div className="WishlistLogo" >
          {"spree"}
        </div>
        <Link className="DashboardHeaderButton" to={"../../analytics/"} relative="path" >
          {"Analytics"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../../customers/"} relative="path" >
          {"Customers"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../../products/"} relative="path" >
          {"Products"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../../rules/"} relative="path" >
          {"Rules"}
        </Link>
      </div>
      </div>
      <div className="WishlistContainerHorizontal" >
        <div className="WishlistContainerMaxWidth" >
          <div className="DashboardTitleContainer" >
            <div className="DashboardTitle" >
              {"Single Customer View for " + username}
            </div>
            {paused &&
              <div className="DashboardLiveButton" >
                <div className="DashboardLiveButtonText" >
                  {"Live Updates Paused"}
                </div>
                <svg className="DashboardPauseButton" viewBox="0 0 12 12" width="14" onClick={() => setPaused(false)} >
                  <polygon points="0,0 10,6 0,12" />
                </svg>
              </div>}
            {!paused &&
              <div className="DashboardLiveButton" >
                {blinkingRedDot &&
                <svg className="DashboardLiveDot" viewBox="0 0 12 12" width="14" >
                  <circle cx="6" cy="6" r="3" fill="red" />
                </svg>}
                <div className="DashboardLiveButtonText" >
                  {"Updating Live"}
                </div>
                <svg className="DashboardPauseButton" viewBox="0 0 12 12" width="14" onClick={() => setPaused(true)} >
                  <polygon points="0,0 3,0 3,12 0,12" />
                  <polygon points="5,0 8,0 8,12 5,12" />
                </svg>
              </div>}
          </div>
          <div className="DashboardTopContainer" >
            <div className="DashboardDemographicsContainer" >
              <div className="DashboardDemographicsTitle" >
                {"Demographics"}
              </div>
              <div className="DashboardDemographicsGrid" >
                <div className="DashboardDemographicsColumn" >
                  <div className="DashboardDemographicsItem" >
                    <div className="DashboardBoldText" >
                    {"Age: "}
                    </div>
                    {age}
                  </div>
                  <div className="DashboardDemographicsItem" >
                    <div className="DashboardBoldText" >
                    {"Height: "}
                    </div>
                    {height}
                  </div>
                  <div className="DashboardDemographicsItem" >
                    <div className="DashboardBoldText" >
                    {"Gender: "}
                    </div>
                    {gender}
                  </div>
                </div>
                <div className="DashboardDemographicsColumn" >
                  <div className="DashboardDemographicsItem" >
                    <div className="DashboardBoldText" >
                    {"Hair Color: "}
                    </div>
                    {hairColor}
                  </div>
                  <div className="DashboardDemographicsItem" >
                    <div className="DashboardBoldText" >
                    {"Skin Tone: "}
                    </div>
                    {skinTone}
                  </div>
                  <div className="DashboardDemographicsItem" >
                    <div className="DashboardBoldText" >
                    {"Body Type: "}
                    </div>
                    {bodyType}
                  </div>
                </div>
                <div className="DashboardDemographicsColumn" >
                  <div className="DashboardDemographicsItem" >
                    <div className="DashboardBoldText" >
                    {"Clothing Size: "}
                    </div>
                    {clothingSize}
                  </div>
                  <div className="DashboardDemographicsItem" >
                    <div className="DashboardBoldText" >
                    {"Shoe Size: "}
                    </div>
                    {shoeSize}
                  </div>
                  <div className="DashboardDemographicsItem" >
                    <div className="DashboardBoldText" >
                    {"Location: "}
                    </div>
                    {loc}
                  </div>
                </div>
              </div>
            </div>
            <div className="DashboardDemographicsContainer" >
              <div className="DashboardBehaviorGrid" >
                <div className="DashboardBehaviorColumn" >
                  <div className="DashboardDemographicsTitle" >
                    {"Purchase Behavior Summary"}
                  </div>
                  <div className="DashboardDemographicsItem" >
                    <div className="DashboardBoldText" >
                    {"Price Point: "}
                    </div>
                    {topPriceRange}
                  </div>
                  <div className="DashboardDemographicsItem" >
                    <div className="DashboardBoldText" >
                    {"Top Brands: "}
                    </div>
                    {topBrands.join(", ")}
                  </div>
                  <div className="DashboardDemographicsItem" >
                    <div className="DashboardBoldText" >
                    {"Activity Level: "}
                    </div>
                    {"Active " + daysActive + " of the last 30 days"}
                  </div>
                </div>
                <div className="DashboardBehaviorColumn" >
                  <div className="DashboardDemographicsTitle" >
                    {"Current Intent"}
                  </div>
                  <div className="DashboardDemographicsItem" >
                    <div className="DashboardBoldText" >
                    {"Actively Looking For: "}
                    </div>
                    {topCategoryList}
                  </div>
                  <div className="DashboardDemographicsItem" >
                    <div className="DashboardBoldText" >
                    {"Interested In: "}
                    </div>
                    {topCategories.join(", ")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="DashboardProductsContainer" >
            <div className="DashboardDemographicsTitle" >
              {"Currently Viewing: " + currentPage}
            </div>
            <div className="DashboardCurrentlyViewingProducts" >
              <div className="DashboardCurrentlyViewingTopRow" >
              {viewing.map(item => (
                <div className="DashboardProduct" >
                  <div className="DashboardProductCaption" >
                    {item.name}
                  </div>
                </div>))}
              </div>
              <div className="DashboardCurrentlyViewingBottomRow" >
              {viewing.map(item => (
                <div className="DashboardProduct" >
                  <img className="DashboardImage" src={item.imageEndpoint} />
                  <div className="DashboardLikelihoodCaption" >
                    {Math.round((categoryLikelihoods[item.generalCategory] || 1) * (priceLikelihoods[item.priceRange] || 1) * 10) / 10 + "x"}
                  </div>
                  {(item.generalCategory != null) &&
                    <div className="DashboardLikelihoodCaption" >
                      {item.generalCategory + ": " + Math.round((categoryLikelihoods[item.generalCategory] || 1) * 10) / 10 + "x"}
                    </div>}
                  {(item.priceRange != null) &&
                    <div className="DashboardLikelihoodCaption" >
                      {item.priceRange + ": " + Math.round((priceLikelihoods[item.priceRange] || 1) * 10) / 10 + "x"}
                    </div>}
                </div>))}
              </div>
            </div>
          </div>
          <div className="DashboardProductsContainer" >
            <div className="DashboardDemographicsTitle" >
              {"Most Likely to Buy from " + brandName}
            </div>
            <div className="DashboardCurrentlyViewingProducts" >
              <div className="DashboardCurrentlyViewingTopRow" >
              {topProducts.map(item => (
                <div className="DashboardProduct" >
                  <div className="DashboardProductCaption" >
                    {item.name}
                  </div>
                </div>))}
              </div>
              <div className="DashboardCurrentlyViewingBottomRow" >
              {topProducts.map(item => (
                <div className="DashboardProduct" >
                  <img className="DashboardImage" src={item.imageEndpoint} />
                  <div className="DashboardLikelihoodCaption" >
                    {item.likelihood + "x"}
                  </div>
                  {(item.general_category != null && item.category_likelihood != null) &&
                    <div className="DashboardLikelihoodCaption" >
                      {item.general_category + ": " + item.category_likelihood + "x"}
                    </div>}
                  {(item.price_range != null && item.price_likelihood != null) &&
                    <div className="DashboardLikelihoodCaption" >
                      {item.price_range + ": " + item.price_likelihood + "x"}
                    </div>}
                </div>))}
              </div>
            </div>
          </div>
        </div>
        <div className="DashboardSidebarContainer" >
        <div className="DashboardSidebar" >
          <div className="DashboardActivityLogTitle" >
            {"Global Activity Log"}
          </div>
          <div className="DashboardActivityLog" >
            {activityLog.map(log => (
              <div className="DashboardActivityItem" >
                {log}
              </div>))}
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
