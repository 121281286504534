import './wishlist.css';
import './dashboard.css';
import './brandProducts.css';
import {useState, useEffect} from 'react';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import { IoCaretDown, IoCaretForward } from "react-icons/io5";

const BrandProducts = () => {
  const { brandId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Spree Dashboard";
    window.history.replaceState({}, "");
  }, []);

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState({});

  const loadProducts = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL+"/v2/dashboard/" + brandId + "/variants/", {
      method: 'GET',
      credentials: 'include',
    });
    if (response.ok) {
      const json = await response.json();
      setProducts(json.variants);
      let loadedCategories = json.categories;
      for (const [key, value] of Object.entries(loadedCategories)) {
        const singleItem = getSingleItem(key, loadedCategories);
        if (!value.alternatives.includes(singleItem)) {
          // add a default subcategory for items assigned to this general category
          // but this default subcategory assignment doesn't get saved; you must
          // further assign the item to a valid subcategory
          value.alternatives.push(singleItem);
          value.subcategories[singleItem] = [singleItem];
          value.singleItem = singleItem;
        }
      }
      setCategories(loadedCategories);
      if (chosenAttribute.startsWith("Custom: ") && !(chosenAttribute.substring(8) in json.custom)) {
        setShowColors(true);
        setChosenAttribute("Color: White");
      }
      setCustomAttributes(json.custom);
      setCanSave(false);
    } else if (response.status == 403) {
      navigate("/dashboard/" + brandId + "/login/");
    }
  };
  useEffect(() => {
    loadProducts();
  }, []);

  const colors = ["White", "Black", "Gray", "Silver", "Blue", "Green", "Red", "Pink", "Purple", "Yellow", "Gold", "Orange", "Beige", "Brown", "Transparent", "Multicolor", "Striped", "Floral", "Checkered", "Plaid", "Dotted", "Cheetah-print", "Zebra-print", "Tortoise-shell"];
  const genders = ["Women", "Men", "Unisex"];
  const [chosenAttribute, setChosenAttribute] = useState((state == null || state.chosenAttribute == undefined) ? "Color: White" : state.chosenAttribute);

  const getCookie = (name) => {
    if (document.cookie && document.cookie != "") {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) == name + "=") {
          return decodeURIComponent(cookie.substring(name.length + 1));
        }
      }
    }
    return null;
  };

  // sets of ids; a new set is created when the save button is pressed
  // to distinguish ids edited before the save versus after
  const [editedIds, setEditedIds] = useState([new Set()]);
  const [canSave, setCanSave] = useState(false);
  const saveChanges = async () => {
    if (!canSave) {
      return;
    }
    const numSets = editedIds.length;
    const updates = products.filter(p => editedIds.some((idSet) => idSet.has(p.id))).map(function(p) {
      return {
        id: p.id,
        category: p.category,
        color: p.color,
        gender: p.gender,
      };
    });
    let newEditedIds = [...editedIds];
    newEditedIds.push(new Set());
    setEditedIds(newEditedIds);

    const csrftoken = getCookie('csrftoken');
    const response = await fetch(process.env.REACT_APP_API_URL+"/v2/dashboard/" + brandId + "/variants/update/", {
      method: 'POST',
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify({variants: updates, custom: customAttributes})
    });
    if (response.ok) {
      setCanSave(false);
      let simplifiedEditedIds = [...editedIds];
      simplifiedEditedIds = simplifiedEditedIds.slice(numSets);
      setEditedIds(simplifiedEditedIds);
      const listIdAssignments = await response.json();
      let newCustomAttributes = {...customAttributes};
      for (let listName of Object.keys(listIdAssignments)) {
        newCustomAttributes[listName].listId = listIdAssignments[listName];
      }
      setCustomAttributes(newCustomAttributes);
    }
  };

  const getSingleItem = (category, allCategories=null) => {
    if (allCategories == null) {
      allCategories = categories;
    }
    const options = allCategories[category].alternatives;
    if (options.includes(category)) {
      return category;
    } else if (category.endsWith("s") && options.includes(category.substring(0, category.length - 1))) {
      return category.substring(0, category.length - 1);
    } else if (category.endsWith("es") && options.includes(category.substring(0, category.length - 2))) {
      return category.substring(0, category.length - 2);
    } else {
      return category;
    }
  };

  const toggleCheck = (id) => {
    setCanSave(true);
    if (chosenAttribute.startsWith("Color: ")) {
      let newProducts = [...products];
      if (newProducts.filter(item => item.id == id)[0].color == chosenAttribute.substring(7)) {
        newProducts.filter(item => item.id == id)[0].color = null;
      } else {
        newProducts.filter(item => item.id == id)[0].color = chosenAttribute.substring(7);
      }
      setProducts(newProducts);
    } else if (chosenAttribute.startsWith("Category: ") && !chosenAttribute.includes(" > ")) {
      let newProducts = [...products];
      if (categories[chosenAttribute.substring(10)].alternatives.includes(newProducts.filter(item => item.id == id)[0].category)) {
        newProducts.filter(item => item.id == id)[0].category = null;
      } else {
        newProducts.filter(item => item.id == id)[0].category = getSingleItem(chosenAttribute.substring(10));
      }
      setProducts(newProducts);
    } else if (chosenAttribute.startsWith("Category: ")) {
      let newProducts = [...products];
      const subcategory = chosenAttribute.split(" > ")[1];
      const category = chosenAttribute.substring(10).split(" > ")[0];
      if (categories[category].subcategories[subcategory].includes(newProducts.filter(item => item.id == id)[0].category)) {
        newProducts.filter(item => item.id == id)[0].category = getSingleItem(category);
      } else {
        newProducts.filter(item => item.id == id)[0].category = subcategory;
      }
      setProducts(newProducts);
    } else if (chosenAttribute.startsWith("Gender: ")) {
      let newProducts = [...products];
      if (newProducts.filter(item => item.id == id)[0].gender == chosenAttribute.substring(8)) {
        newProducts.filter(item => item.id == id)[0].gender = null;
      } else {
        newProducts.filter(item => item.id == id)[0].gender = chosenAttribute.substring(8);
      }
      setProducts(newProducts);
    } else if (chosenAttribute.startsWith("Custom: ")) {
      let newCustomAttributes = {...customAttributes};
      if (newCustomAttributes[chosenAttribute.substring(8)].variants.includes(id)) {
        const index = newCustomAttributes[chosenAttribute.substring(8)].variants.indexOf(id);
        newCustomAttributes[chosenAttribute.substring(8)].variants.splice(index, 1);
      } else {
        newCustomAttributes[chosenAttribute.substring(8)].variants.push(id);
      }
      setCustomAttributes(newCustomAttributes);
    }
    let newEditedIds = [...editedIds];
    newEditedIds[newEditedIds.length - 1].add(id);
    setEditedIds(newEditedIds);
  };

  const isChecked = (item) => {
    if (chosenAttribute.startsWith("Color: ")) {
      return (item.color == chosenAttribute.substring(7));
    } else if (chosenAttribute.startsWith("Category: ")) {
      if (!chosenAttribute.includes(" > ")) {
        return categories[chosenAttribute.substring(10)].alternatives.includes(item.category);
      } else {
        const subcategory = chosenAttribute.split(" > ")[1];
        const category = chosenAttribute.substring(10).split(" > ")[0];
        return categories[category].subcategories[subcategory].includes(item.category);
      }
    } else if (chosenAttribute.startsWith("Gender: ")) {
      return (item.gender == chosenAttribute.substring(8));
    } else if (chosenAttribute.startsWith("Custom: ")) {
      return customAttributes[chosenAttribute.substring(8)].variants.includes(item.id);
    }
  };

  const pageLength = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const nextPageAvailable = () => {
    return (Math.ceil(filtered(products).length / pageLength) > currentPage);
  };
  const previousPageAvailable = () => {
    return (currentPage > 1);
  };
  const totalPages = () => {
    return Math.max(Math.ceil(filtered(products).length / pageLength), 1);
  };

  const [categoryFilter, setCategoryFilter] = useState("all");
  const changeCategoryFilter = (evt) => {
    setCategoryFilter(evt.target.value);
    setCurrentPage(1);
  };
  const [colorFilter, setColorFilter] = useState("all");
  const changeColorFilter = (evt) => {
    setColorFilter(evt.target.value);
    setCurrentPage(1);
  };
  const [genderFilter, setGenderFilter] = useState("all");
  const changeGenderFilter = (evt) => {
    setGenderFilter(evt.target.value);
    setCurrentPage(1);
  };
  const filtered = (ps) => {
    let data = ps;
    data = ps.filter(item => (categoryFilter == "all") || (categoryFilter == "none" && item.category == null) || (Object.keys(categories).includes(categoryFilter) && categories[categoryFilter].alternatives.includes(item.category)) || (chosenAttribute.startsWith("Category: ") && !chosenAttribute.includes(" > ") && categories[chosenAttribute.substring(10)].alternatives.includes(item.category)) || (chosenAttribute.startsWith("Category: ") && chosenAttribute.includes(" > ") && categories[chosenAttribute.substring(10).split(" > ")[0]].subcategories[chosenAttribute.split(" > ")[1]].includes(item.category)));
    data = data.filter(item => (colorFilter == "all") || (colorFilter == "none" && item.color == null) || (colorFilter == "none" && !colors.includes(item.color)) || (item.color == colorFilter) || (chosenAttribute.startsWith("Color: ") && item.color == chosenAttribute.substring(7)));
    data = data.filter(item => (genderFilter == "all") || (genderFilter == "none" && item.gender == null) || (item.gender == genderFilter) || (chosenAttribute.startsWith("Gender: ") && item.gender == chosenAttribute.substring(8)));
    return data;
  };

  const selectChosenAttribute = (attr) => {
    if (attr == chosenAttribute && attr.startsWith("Category: ") && !attr.includes(" > ")) {
      setShowSubcategories(val => !val);
    } else {
      setShowSubcategories(true);
    }
    if (!(attr.startsWith("Category: ") && attr.includes(" > ") && chosenAttribute.startsWith("Category: ") && chosenAttribute.includes(" > "))) {
      setCurrentPage(1);
    }

    setChosenAttribute(attr);
    if (attr.startsWith("Category: ") && !attr.includes(" > ")) {
      setCategoryFilter("none");
      setColorFilter("all");
      setGenderFilter("all");
    } else if (attr.startsWith("Category: ")) {
      const category = attr.substring(10).split(" > ")[0];
      setCategoryFilter(category);
      setColorFilter("all");
      setGenderFilter("all");
    } else if (attr.startsWith("Color: ")) {
      setCategoryFilter("all");
      setColorFilter("none");
      setGenderFilter("all");
    } else if (attr.startsWith("Gender: ")) {
      setCategoryFilter("all");
      setColorFilter("all");
      setGenderFilter("none");
    } else if (attr.startsWith("Custom: ")) {
      setCategoryFilter("all");
      setColorFilter("all");
      setGenderFilter("all");
    }
  };

  const [showCategories, setShowCategories] = useState((state != null && state.newCustom) ? false : true);
  const [showColors, setShowColors] = useState((state != null && state.newCustom) ? false : true);
  const [showGenders, setShowGenders] = useState((state != null && state.newCustom) ? false : true);
  const [showCustom, setShowCustom] = useState(true);
  const [showSubcategories, setShowSubcategories] = useState(true);

  const [customAttributes, setCustomAttributes] = useState({});
  const [showDraftAttribute, setShowDraftAttribute] = useState((state != null && state.newCustom) ? true : false);
  const createDraftAttribute = () => {
    setShowDraftAttribute(true);
    setShowCategories(false);
    setShowColors(false);
    setShowGenders(false);
    setShowCustom(true);
  };
  const enterToSaveDraft = (evt) => {
    if (evt.key == 'Enter') {
      let newCustomAttributes = {...customAttributes};
      if (evt.target.value in newCustomAttributes) {
        // do nothing
        return;
      }
      newCustomAttributes[evt.target.value] = {listId: null, variants: []};
      setCustomAttributes(newCustomAttributes);
      setShowDraftAttribute(false);
      selectChosenAttribute("Custom: " + evt.target.value);
      setCanSave(true);
    }
  };
  const toggleShowCustom = () => {
    if (showCustom) {
      setShowCustom(false);
      setShowDraftAttribute(false);
    } else {
      setShowCustom(true);
    }
  };
  const deleteCustomAttribute = () => {
    if (chosenAttribute.startsWith("Custom: ") && chosenAttribute.substring(8) in customAttributes) {
      let newCustomAttributes = {...customAttributes};
      delete newCustomAttributes[chosenAttribute.substring(8)];
      setCustomAttributes(newCustomAttributes);
      setShowColors(true);
      selectChosenAttribute("Color: White");
      setCanSave(true);
    }
  };

  return (
    <div className="WishlistContainerVertical" >
      <div className="WishlistHeader" >
      <div className="WishlistHeaderMaxWidth" >
        <div className="WishlistLogo" >
          {"spree"}
        </div>
        <Link className="DashboardHeaderButton" to={"../analytics/"} relative="path" >
          {"Analytics"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../customers/"} relative="path" >
          {"Customers"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../products/"} relative="path" >
          {"Products"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../rules/"} relative="path" >
          {"Rules"}
        </Link>
      </div>
      </div>
      <div className="WishlistContainerHorizontal" >
      <div className="WishlistContainerMaxWidth" >
        <div className="BPTitleContainer" >
          <div className="BPTitle" >
            {"Products Editor"}
          </div>
          <div className="BPButtons" >
            {(chosenAttribute.startsWith("Custom: ")) &&
              <div className="BPDeleteButton" onClick={deleteCustomAttribute} >
                {"Delete"}
              </div>}
            {canSave &&
              <div className="BPDeleteButton" onClick={loadProducts} >
                {"Reset"}
              </div>}
            <div className={canSave ? "BPSaveButton" : "BPSaveButtonDisabled"} onClick={saveChanges} >
              {"Save"}
            </div>
          </div>
        </div>
        <div className="BPBody" >
          <div className="BPAttributesBar" >
            <div className="BPAttributesTitle" >
              {"All Attributes"}
            </div>
            <div className="BPNewAttributeView" >
            <div className="BPNewAttribute" onClick={createDraftAttribute} >
              {"+ New"}
            </div>
            </div>
            <div className="BPAttributeType" onClick={() => setShowCategories(val => !val)} >
              {"Category"}
              {showCategories ? <IoCaretDown className="BPCaretDown" /> : <IoCaretForward className="BPCaretDown" />}
            </div>
            {showCategories && Object.keys(categories).map(item => (
              <>
              <div className={(chosenAttribute == "Category: " + item) ? "BPAttributeNameClicked" : "BPAttributeName"} onClick={() => selectChosenAttribute("Category: " + item)} >
                <div className="BPAttributeNameText" >
                  {item}
                </div>
                <div className="BPAttributeNameText" >
                  {products.filter(p => categories[item].alternatives.includes(p.category)).length}
                </div>
              </div>
              {(showSubcategories && (chosenAttribute == "Category: " + item || chosenAttribute.startsWith("Category: " + item + " > "))) && Object.keys(categories[item].subcategories).map(sub => (
                <div className={(chosenAttribute == "Category: " + item + " > " + sub) ? "BPSubAttributeNameClicked" : "BPSubAttributeName"} onClick={() => selectChosenAttribute("Category: " + item + " > " + sub)} >
                  <div className={(sub == categories[item].singleItem) ? "BPAttributeNameTextRed" : "BPAttributeNameText"} >
                    {sub}
                  </div>
                  <div className="BPAttributeNameText" >
                    {products.filter(p => categories[item].subcategories[sub].includes(p.category)).length}
                  </div>
                </div>))}
              </>))}
            <div className="BPAttributeType" onClick={() => setShowColors(val => !val)} >
              {"Color"}
              {showColors ? <IoCaretDown className="BPCaretDown" /> : <IoCaretForward className="BPCaretDown" />}
            </div>
            {showColors && colors.map(item => (
              <div className={(chosenAttribute == "Color: " + item) ? "BPAttributeNameClicked" : "BPAttributeName"} onClick={() => selectChosenAttribute("Color: " + item)} >
                <div className="BPAttributeNameText" >
                  {item}
                </div>
                <div className="BPAttributeNameText" >
                  {products.filter(p => p.color == item).length}
                </div>
              </div>))}
            <div className="BPAttributeType" onClick={() => setShowGenders(val => !val)} >
              {"Gender"}
              {showGenders ? <IoCaretDown className="BPCaretDown" /> : <IoCaretForward className="BPCaretDown" />}
            </div>
            {showGenders && genders.map(item => (
              <div className={(chosenAttribute == "Gender: " + item) ? "BPAttributeNameClicked" : "BPAttributeName"} onClick={() => selectChosenAttribute("Gender: " + item)} >
                <div className="BPAttributeNameText" >
                  {item}
                </div>
                <div className="BPAttributeNameText" >
                  {products.filter(p => p.gender == item).length}
                </div>
              </div>))}
            <div className="BPAttributeType" onClick={toggleShowCustom} >
              {"Custom"}
              {showCustom ? <IoCaretDown className="BPCaretDown" /> : <IoCaretForward className="BPCaretDown" />}
            </div>
            {showDraftAttribute &&
              <div className="BPDraftAttributeContainer" >
                <input autoFocus type="text" className="BPDraftAttribute" onKeyDown={enterToSaveDraft} />
              </div>}
            {showCustom && Object.keys(customAttributes).map(key => (
              <div className={(chosenAttribute == "Custom: " + key) ? "BPAttributeNameClicked" : "BPAttributeName"} onClick={() => selectChosenAttribute("Custom: " + key)} >
                <div className="BPAttributeNameText" >
                  {key}
                </div>
                <div className="BPAttributeNameText" >
                  {customAttributes[key].variants.length}
                </div>
              </div>))}
          </div>
          <div className="BPProductsNavigator" >
            <div className="BPCurrentAttributeHeader" >
              <div className="BPCurrentAttribute" >
                {chosenAttribute}
              </div>
              <div className="BPCurrentCount" >
                {products.filter(item => isChecked(item)).length + " Products"}
              </div>
            </div>
            <div className="BPFilters" >
              <div className="BPFiltersTitle" >
                {"Filters:"}
              </div>
              <select className="BPFilterView" value={categoryFilter} onChange={changeCategoryFilter} >
                <option value={"all"} >
                  {"Category"}
                </option>
                <option value={"none"} >
                  {"No category"}
                </option>
                {Object.keys(categories).map(item => (
                  <option value={item} >
                    {item}
                  </option>))}
              </select>
              <select className="BPFilterView" value={colorFilter} onChange={changeColorFilter} >
                <option value={"all"} >
                  {"Color"}
                </option>
                <option value={"none"} >
                  {"No color"}
                </option>
                {colors.map(item => (
                  <option value={item} >
                    {item}
                  </option>))}
              </select>
              <select className="BPFilterView" value={genderFilter} onChange={changeGenderFilter} >
                <option value={"all"} >
                  {"Gender"}
                </option>
                <option value={"none"} >
                  {"No gender"}
                </option>
                {genders.map(item => (
                  <option value={item} >
                    {item}
                  </option>))}
              </select>
            </div>
            <div className="BPProductGrid" >
              {filtered(products).filter((item, index) => index >= (currentPage - 1) * pageLength && index < currentPage * pageLength).map(item => (
                <div className={isChecked(item) ? "BPProductChecked" : "BPProduct"} onClick={() => toggleCheck(item.id)} >
                  <div className="BPProductInfo" >
                    <img className="BPImage" src={item.imageEndpoint} />
                    <div className="BPProductCaption" >
                      {item.name}
                    </div>
                  </div>
                  <div className="BPProductAttributes" >
                    {chosenAttribute.startsWith("Color: ") &&
                      <div className="BPProductCaption" >
                        {(item.color == null) ? "No Color" : item.color}
                      </div>}
                    {chosenAttribute.startsWith("Gender: ") &&
                      <div className="BPProductCaption" >
                        {(item.gender == null) ? "No Gender" : item.gender}
                      </div>}
                    {chosenAttribute.startsWith("Category: ") &&
                      <div className="BPProductCaption" >
                        {(item.category == null) ? "No Category" : item.category}
                      </div>}
                    <input type="checkbox" checked={isChecked(item)} />
                  </div>
                </div>))}
            </div>
            <div className="BPPaginator" >
                <div className={previousPageAvailable() ? "BPPaginatorTextLink" : "BPPaginatorTextHidden"} onClick={() => setCurrentPage(1)} >
                  {"<<"}
                </div>
                <div className={previousPageAvailable() ? "BPPaginatorTextLink" : "BPPaginatorTextHidden"} onClick={() => setCurrentPage(i => i-1)} >
                  {"Previous Page"}
                </div>
                <div className="BPPaginatorText" >
                  {"Page " + currentPage + " of " + totalPages()}
                </div>
                <div className={nextPageAvailable() ? "BPPaginatorTextLink" : "BPPaginatorTextHidden"} onClick={() => setCurrentPage(i => i+1)} >
                  {"Next Page"}
                </div>
                <div className={nextPageAvailable() ? "BPPaginatorTextLink" : "BPPaginatorTextHidden"} onClick={() => setCurrentPage(totalPages())} >
                  {">>"}
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default BrandProducts;
