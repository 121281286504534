import './pageNotFound.css';

const PageNotFound = () => {
  return (
    <div className="PageNotFound" >
      {"Sorry, the page you were looking for was not found."}
    </div>
  );
};

export default PageNotFound;
