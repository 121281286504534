import './wishlist.css';
import './dashboard.css';
import './customers.css';
import {useEffect, useState} from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import Select from 'react-select';


const Customers = () => {
  const { brandId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Spree Dashboard";
    window.history.replaceState({}, "");
  }, []);

  const [chosenHairs, setChosenHairs] = useState((state == null) ? [] : state.hairs);
  const [chosenLocs, setChosenLocs] = useState((state == null) ? [] : state.locs);
  const [chosenGender, setChosenGender] = useState((state == null) ? null : state.gender);
  const [chosenAges, setChosenAges] = useState((state == null) ? [] : state.ages);
  const [chosenSizes, setChosenSizes] = useState((state == null) ? [] : state.sizes);
  const [chosenSkintones, setChosenSkintones] = useState((state == null) ? [] : state.skintones);

  const [customerData, setCustomerData] = useState([]);
  const loadCustomers = async () => {
    const queryParams = new URLSearchParams();
    queryParams.append("demo", "true");
    for (let hair of chosenHairs) {
      queryParams.append("hair", hair);
    }
    for (let loc of chosenLocs) {
      queryParams.append("loc", loc);
    }
    for (let age of chosenAges) {
      queryParams.append("age", age);
    }
    for (let size of chosenSizes) {
      queryParams.append("size", size);
    }
    if (chosenGender != null) {
      queryParams.append("gender", chosenGender);
    }
    for (let skintone of chosenSkintones) {
      queryParams.append("skintone", skintone);
    }
    const response = await fetch(process.env.REACT_APP_API_URL+"/v2/dashboard/" + brandId + "/users/info?" + queryParams, {
      method: 'GET',
      credentials: 'include',
    });
    if (response.ok) {
      const json = await response.json();
      setCustomerData(json);
    } else if (response.status == 403) {
      navigate("/dashboard/" + brandId + "/login/");
    }
  };
  useEffect(() => {
    loadCustomers();
  }, [chosenAges, chosenSizes, chosenGender, chosenLocs, chosenSkintones, chosenHairs]);

  const goToCustomer = (userId) => {
    navigate("/dashboard/" + brandId + "/customer/" + userId);
  };

  const onHairFilterChange = (options) => {
    setChosenHairs(options.map(opt => opt.value));
  };
  const onLocFilterChange = (options) => {
    setChosenLocs(options.map(opt => opt.value));
  };
  const onAgeFilterChange = (options) => {
    setChosenAges(options.map(opt => opt.value));
  };
  const onSizeFilterChange = (options) => {
    setChosenSizes(options.map(opt => opt.value));
  };
  const onSkintoneFilterChange = (options) => {
    setChosenSkintones(options.map(opt => opt.value));
  };
  const onGenderFilterChange = (option) => {
    if (option != null) {
      setChosenGender(option.value);
    } else {
      setChosenGender(null);
    }
  };

  const selectStyles = {
    container: css => ({...css, "flex-shrink": 0})
  };
  const hairOptions = ["Blonde", "Red", "Brown", "Black", "Gray"].map(item => ({value: item, label: item}));
  const skintoneOptions = ["Pale", "Fair", "Tan", "Brown", "Black"].map(item => ({value: item, label: item}));
  const sizeOptions = ["XS", "S", "M", "L", "XL"].map(item => ({value: item, label: item}));
  const genderOptions = [{value: "Female", label: "Female"}, {value: "Male", label: "Male"}];
  const ageOptions = [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].map(n => ({value: n, label: n}));
  const locOptions = ["New York City", "Los Angeles", "Chicago", "Dallas", "Houston", "Washington, D.C.", "Philadelphia", "Atlanta", "Miami", "Detroit", "San Diego", "Phoenix", "Boston", "San Francisco", "Seattle", "Minneapolis", "Tampa", "Denver", "Baltimore", "St. Louis", "Orlando", "Charlotte", "San Antonio", "Portland, OR", "Pittsburgh", "Austin", "Sacramento", "El Paso", "Las Vegas", "Cincinnati", "Kansas City", "Columbus", "Cleveland", "Indianapolis", "Nashville"].map(item => ({value: item, label: item}));

  return (
    <div className="WishlistContainerVertical" >
      <div className="WishlistHeader" >
      <div className="WishlistHeaderMaxWidth" >
        <div className="WishlistLogo" >
          {"spree"}
        </div>
        <Link className="DashboardHeaderButton" to={"../analytics/"} relative="path" >
          {"Analytics"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../customers/"} relative="path" >
          {"Customers"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../products/"} relative="path" >
          {"Products"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../rules/"} relative="path" >
          {"Rules"}
        </Link>
      </div>
      </div>
      <div className="WishlistContainerHorizontal" >
        <div className="WishlistContainerMaxWidth" >
          <div className="CustomersTitle" >
            {"Explore Customers"}
          </div>
          <div className="CustomersSubtitleRow" >
            <div className="CustomersFiltersView" >
              <Select
                className="CustomersFilter"
                options={hairOptions}
                placeholder={"Hair Color"}
                isSearchable={false}
                isMulti={true}
                isClearable={false}
                onChange={onHairFilterChange}
                styles={selectStyles}
                menuPortalTarget={document.querySelector("body")}
                defaultValue={(state == null) ? null : state.hairs.map(hair => ({label: hair, value: hair}))}
              />
              <Select
                className="CustomersFilter"
                options={locOptions}
                placeholder={"Location"}
                isSearchable={false}
                isMulti={true}
                isClearable={false}
                onChange={onLocFilterChange}
                styles={selectStyles}
                menuPortalTarget={document.querySelector("body")}
                defaultValue={(state == null) ? null : state.locs.map(loc => ({label: loc, value: loc}))}
              />
              <Select
                className="CustomersFilter"
                options={ageOptions}
                placeholder={"Age"}
                isSearchable={false}
                isMulti={true}
                isClearable={false}
                onChange={onAgeFilterChange}
                styles={selectStyles}
                menuPortalTarget={document.querySelector("body")}
                defaultValue={(state == null) ? null : state.ages.map(age => ({label: age, value: age}))}
              />
              <Select
                className="CustomersFilter"
                options={skintoneOptions}
                placeholder={"Skin Tone"}
                isSearchable={false}
                isMulti={true}
                isClearable={false}
                onChange={onSkintoneFilterChange}
                styles={selectStyles}
                menuPortalTarget={document.querySelector("body")}
                defaultValue={(state == null) ? null : state.skintones.map(skintone => ({label: skintone, value: skintone}))}
              />
              <Select
                className="CustomersFilter"
                options={sizeOptions}
                placeholder={"Size"}
                isSearchable={false}
                isMulti={true}
                isClearable={false}
                onChange={onSizeFilterChange}
                styles={selectStyles}
                menuPortalTarget={document.querySelector("body")}
                defaultValue={(state == null) ? null : state.sizes.map(size => ({label: size, value: size}))}
              />
              <Select
                className="CustomersFilter"
                options={genderOptions}
                placeholder={"Gender"}
                isSearchable={false}
                isClearable={true}
                onChange={onGenderFilterChange}
                styles={selectStyles}
                menuPortalTarget={document.querySelector("body")}
                defaultValue={(state == null || state.gender == null) ? null : {label: state.gender, value: state.gender}}
              />
            </div>
            <div className="CustomersCount" >
              {customerData.length}
              {" customers"}
            </div>
          </div>
          <div className="CustomersList" >
            {customerData.map(customer => (
              <div className="CustomersSingle" onClick={() => goToCustomer(customer.user_id)} >
                <div className="CustomersSingleDemographics" >
                  <div className="CustomersSingleTitle" >
                    {customer.username}
                  </div>
                  <div className="CustomersSingleDemographicsGrid" >
                    <div className="CustomersSingleDemographicsColumn" >
                      <div className="CustomersSingleDemographic" >
                        {"Age: "}
                        {customer.age}
                      </div>
                      <div className="CustomersSingleDemographic" >
                        {"Location: "}
                        {customer.loc}
                      </div>
                    </div>
                    <div className="CustomersSingleDemographicsColumn" >
                      <div className="CustomersSingleDemographic" >
                        {"Gender: "}
                        {customer.gender}
                      </div>
                      <div className="CustomersSingleDemographic" >
                        {"Skin Tone: "}
                        {customer.skinTone}
                      </div>
                    </div>
                    <div className="CustomersSingleDemographicsColumn" >
                      <div className="CustomersSingleDemographic" >
                        {"Size: "}
                        {customer.clothingSize}
                      </div>
                      <div className="CustomersSingleDemographic" >
                        {"Hair Color: "}
                        {customer.hairColor}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="CustomersSingleProducts" >
                  {customer.topProducts.map(productImage => (
                    <div className="CustomersProduct" >
                      <img className="CustomersProductImage" src={productImage} />
                    </div>))}
                </div>
              </div>))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
