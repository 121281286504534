import './wishlist.css';
import './brandLogin.css';
import {useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const BrandLogin = () => {
  const { brandId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Spree Dashboard";
  }, []);

  const [logo, setLogo] = useState(null);

  const setup = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL+"/v2/dashboard/" + brandId + "/setup/", {
      method: 'GET',
      credentials: 'include',
    });
    if (response.ok) {
      const json = await response.json();
      setLogo(json.logoEndpoint);
    }
  };
  useEffect(() => {
    setup();
  }, []);

  const getCookie = (name) => {
    if (document.cookie && document.cookie != "") {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) == name + "=") {
          return decodeURIComponent(cookie.substring(name.length + 1));
        }
      }
    }
    return null;
  };

  const [username, setUsername] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const enterToSubmit = async (evt) => {
    if (evt.key == 'Enter') {
      const password = evt.target.value;
      const csrftoken = getCookie('csrftoken');

      const response = await fetch(process.env.REACT_APP_API_URL+"/v2/dashboard/" + brandId + "/login/", {
        method: 'POST',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({username: username, password: password})
      });
      if (response.ok) {
        navigate("/dashboard/" + brandId + "/analytics/");
      } else {
        const json = await response.json();
        setErrorMessage(json.errors);
      }
    }
  };

  return (
    <div className="WishlistContainerVertical" >
      <div className="WishlistHeader" >
      <div className="WishlistHeaderMaxWidth" >
        <div className="WishlistLogo" >
          {"spree"}
        </div>
      </div>
      </div>
      <div className="WishlistContainerHorizontal" >
      <div className="WishlistContainerMaxWidth" >
        <div className="BLoginBox" >
          <img className="BLoginBrandLogo" src={logo} />
          <div className="BLoginTextboxCaption" >
            {"Username:"}
          </div>
          <input autoFocus type="text" className="BLoginTextbox" onInput={(evt) => setUsername(evt.target.value)} />
          <div className="BLoginTextboxCaption" >
            {"Password:"}
          </div>
          <input type="password" className="BLoginTextbox" onKeyDown={enterToSubmit} />
          {(errorMessage != null) &&
            <div className="BLoginErrorMessage" >
              {errorMessage}
            </div>}
        </div>
      </div>
      </div>
    </div>
  );
};

export default BrandLogin;
