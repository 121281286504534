import './wishlist.css';
import {useEffect} from 'react';
import PageNotFound from './pageNotFound';

const PageNotFoundWithHeader = () => {
  useEffect(() => {
    document.title = "Spree | Page Not Found";
  }, []);

  return (
    <div className="WishlistContainerVertical" >
      <div className="WishlistHeader" >
      <div className="WishlistHeaderMaxWidth" >
        <div className="WishlistLogo" >
          {"spree"}
        </div>
        <a href="https://apps.apple.com/us/app/spree-a-fashion-community/id1616281339" target="_blank" >
        <img className="WishlistAppButton" src={require("./images/download-app-store.svg").default}  />
        </a>
      </div>
      </div>
      <div className="WishlistContainerHorizontal" >
      <div className="WishlistContainerMaxWidth" >
        <PageNotFound />
      </div>
      </div>
    </div>
  );
};

export default PageNotFoundWithHeader;
