import { createBrowserRouter, RouterProvider, useParams } from 'react-router-dom';
import PageWrapper from './pageWrapper';
import Wishlist from './wishlist';
import Closet from './closet';
import Profile from './profile';
import List from './list';
import PageNotFoundWithHeader from './pageNotFoundWithHeader';
import Dashboard from './dashboard';
import Rules from './rules';
import BrandProducts from './brandProducts';
import Analytics from './analytics';
import Customers from './customers';
import BrandLogin from './brandLogin';
import BrandSignup from './brandSignup';
import ReactGA from "react-ga4";

const Spree = () => {
  if (!window.location.href.includes("localhost")) {
    ReactGA.initialize("G-6F2GRENETS");
  }

  const router = createBrowserRouter([
    {
      path: "/dashboard/:brandId/customer/:userId",
      element: <PageWrapper element=<Dashboard /> />,
    },
    {
      path: "/dashboard/:brandId/customers/",
      element: <PageWrapper element=<Customers /> />,
    },
    {
      path: "/dashboard/:brandId/rules/",
      element: <PageWrapper element=<Rules /> />,
    },
    {
      path: "/dashboard/:brandId/products/",
      element: <PageWrapper element=<BrandProducts /> />,
    },
    {
      path: "/dashboard/:brandId/analytics/",
      element: <PageWrapper element=<Analytics /> />,
    },
    {
      path: "/dashboard/:brandId/login/",
      element: <PageWrapper element=<BrandLogin /> />,
    },
    {
      path: "/dashboard/:brandId/signup",
      element: <PageWrapper element=<BrandSignup /> />,
    },
    {
      path: "/:username/",
      element: <PageWrapper element=<Profile /> />,
    },
    {
      path: "/:username/wishlist/",
      element: <PageWrapper element=<Wishlist /> />,
    },
    {
      path: "/:username/closet/",
      element: <PageWrapper element=<Closet /> />,
    },
    {
      path: "/:username/list/:listId/:slug",
      element: <PageWrapper element=<List /> />,
    },
    {
      path: "/brand/:brandId/:brandName/list/:listId/:slug",
      element: <PageWrapper element=<List /> />,
    },
    {
      path: "/",
      element: <PageWrapper element=<RedirectToHome /> />,
      errorElement: <PageWrapper element=<PageNotFoundWithHeader /> />,
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
};

function RedirectToHome() {
  window.location.replace("https://spreeshoppingapp.com");
  return null;
};

export default Spree;
