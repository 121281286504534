import './wishlist.css';
import './profile.css';
import { useParams, Link } from 'react-router-dom';
import {useState, useEffect} from 'react';
import { IoChevronForwardOutline } from 'react-icons/io5';
import PageNotFound from './pageNotFound';

const Profile = () => {
  const { username } = useParams();

  useEffect(() => {
    document.title = "Spree | " + username + "'s Profile";
  }, []);

  const [pageNotFound, setPageNotFound] = useState(false);

  const [closetCount, setClosetCount] = useState(0);
  const [wishlistCount, setWishlistCount] = useState(0);
  const [followersCount, setFollowersCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [closetPreview, setClosetPreview] = useState(null);
  const [wishlistPreview, setWishlistPreview] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const loadData = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL+"/v3/web/users/" + username + "/profile/", {
      method: 'GET',
    });
    if (response.ok) {
      const json = await response.json();
      setClosetCount(json.closet_count);
      setWishlistCount(json.wishlist_count);
      setFollowersCount(json.followers_count);
      setFollowingCount(json.following_count);
      setClosetPreview(json.closet_preview);
      setWishlistPreview(json.wishlist_preview);
      setProfilePicture(json.profile_picture_imageEndpoint);
    } else {
      setPageNotFound(true);
      document.title = "Spree | Page Not Found";
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="WishlistContainerVertical" >
      <div className="WishlistHeader" >
      <div className="WishlistHeaderMaxWidth" >
        <div className="WishlistLogo" >
          {"spree"}
        </div>
        <a href="https://apps.apple.com/us/app/spree-a-fashion-community/id1616281339" target="_blank" >
        <img className="WishlistAppButton" src={require("./images/download-app-store.svg").default}  />
        </a>
      </div>
      </div>
      <div className="WishlistContainerHorizontal" >
      <div className="WishlistContainerMaxWidth" >
      {(pageNotFound == true)
      ? (
        <PageNotFound />
      ) : (
      <>
        <div className="WishlistTitle" >
          {username + "'s Profile"}
        </div>
        <div className="ProfileHeader" >
          <div className="ProfilePictureView" >
            <img src={profilePicture} className="ProfilePicture" />
          </div>
          <div className="ProfileStats" >
            <div className="ProfileStatView" >
              <div className="ProfileStatNumber" >
                {closetCount}
              </div>
              <div className="ProfileStatCaption" >
                {"In Closet"}
              </div>
            </div>
            <div className="ProfileStatView" >
              <div className="ProfileStatNumber" >
                {wishlistCount}
              </div>
              <div className="ProfileStatCaption" >
                {"In Wishlist"}
              </div>
            </div>
            <div className="ProfileStatView" >
              <div className="ProfileStatNumber" >
                {followersCount}
              </div>
              <div className="ProfileStatCaption" >
                {"Followers"}
              </div>
            </div>
            <div className="ProfileStatView" >
              <div className="ProfileStatNumber" >
                {followingCount}
              </div>
              <div className="ProfileStatCaption" >
                {"Following"}
              </div>
            </div>
          </div>
        </div>
        <Link className="ProfileSection" to="closet/" >
          <div className="ProfileClosetTitleBar" >
            <div className="ProfileClosetTitle" >
              {"Closet"}
            </div>
            <div className="ProfileClosetSeeAll" >
              {"See all"}
              <IoChevronForwardOutline className="ProfileClosetSeeAllIcon" />
            </div>
          </div>
          <div className="ProfileClosetPreview" >
            {(closetPreview != null) && closetPreview.map((item, i) =>
              <div className="ProfileImageContainer" >
              <img src={item.imageEndpoint} className="ProfileImage" />
              </div>)}
            {(closetPreview != null && closetPreview.length == 0) &&
              <div className="ProfileSectionNoItems" >
                {"No items"}
              </div>}
            {(closetPreview == null) &&
              <div className="ProfileImageContainer" >
              <div className="ProfileImagePlaceholder" />
              </div>}
          </div>
        </Link>
        <Link className="ProfileSection" to="wishlist/" >
          <div className="ProfileClosetTitleBar" >
            <div className="ProfileClosetTitle" >
              {"Wishlist"}
            </div>
            <div className="ProfileClosetSeeAll" >
              {"See all"}
              <IoChevronForwardOutline className="ProfileClosetSeeAllIcon" />
            </div>
          </div>
          <div className="ProfileClosetPreview" >
            {(wishlistPreview != null) && wishlistPreview.map((item, i) =>
              <div className="ProfileImageContainer" >
              <img src={item.imageEndpoint} className="ProfileImage" />
              </div>)}
            {(wishlistPreview != null && wishlistPreview.length == 0) &&
              <div className="ProfileSectionNoItems" >
                {"No items"}
              </div>}
            {(wishlistPreview == null) &&
              <div className="ProfileImageContainer" >
              <div className="ProfileImagePlaceholder" />
              </div>}
          </div>
        </Link>
      </>)}
      </div>
      </div>
    </div>
  );
};

export default Profile;
