import './wishlist.css';
import './dashboard.css';
import './analytics.css';
import {useState, useEffect} from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { BarChart, XAxis, YAxis, Bar, LabelList, PieChart, Pie, Cell } from 'recharts';
import { IoArrowForward } from "react-icons/io5";

const Analytics = () => {
  const { brandId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Spree Dashboard";
  }, []);

  const loadUserInfo = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL+"/v2/dashboard/" + brandId + "/users/info/summary/?demo=true", {
      method: 'GET',
      credentials: 'include',
    });
    if (response.ok) {
      const json = await response.json();
      for (let item of json.gender) {
        if (item.name == "Female") {
          item.color = "pink";
        } else if (item.name == "Male") {
          item.color = "lightblue";
        } else if (item.name == "Non-Binary") {
          item.color = "gray";
        }
      }
      setGenderData(json.gender);
      setAgeData(json.age);
      for (let item of json.clothingSize) {
        if (item.name == "XS") {
          item.color = "#ffa15930";
        } else if (item.name == "S") {
          item.color = "#ffa15960";
        } else if (item.name == "M") {
          item.color = "#ffa15990";
        } else if (item.name == "L") {
          item.color = "#ffa159b0";
        } else if (item.name == "XL") {
          item.color = "#ffa159ff";
        }
      }
      setSizeData(json.clothingSize);
      setSkintoneData(json.skinTone);
      for (let item of json.skinTone) {
        if (item.name == "Pale") {
          item.color = "#ffece0";
        } else if (item.name == "Fair") {
          item.color = "#fcc6b1";
        } else if (item.name == "Tan") {
          item.color = "#cfa46d";
        } else if (item.name == "Brown") {
          item.color = "#8c4524";
        } else if (item.name == "Black") {
          item.color = "#331e02";
        }
      }
      setLocationData(json.loc);
      setHairData(json.hairColor);
      for (let item of json.hairColor) {
        if (item.name == "Blonde") {
          item.color = "#fff2b3";
        } else if (item.name == "Red") {
          item.color = "#ff7e61";
        } else if (item.name == "Brown") {
          item.color = "#d67447";
        } else if (item.name == "Black") {
          item.color = "#1c100a";
        } else if (item.name == "Gray") {
          item.color = "#8f8f8f";
        }
      }
    } else if (response.status == 403) {
      navigate("/dashboard/" + brandId + "/login/");
    }
  };
  useEffect(() => {
    loadUserInfo();
  }, []);

  const [ageData, setAgeData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [sizeData, setSizeData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [skintoneData, setSkintoneData] = useState([]);
  const [hairData, setHairData] = useState([]);

  const [chosenGender, setChosenGender] = useState(null);
  const [highlightedGender, setHighlightedGender] = useState(null);
  const highlightGender = (evt) => {
    if (evt.name != chosenGender) {
      setHighlightedGender(evt.name);
    }
  };
  const unhighlightGender = (evt) => {
    if (evt.name != chosenGender) {
      setHighlightedGender(null);
    }
  };
  const chooseGender = (evt) => {
    if (evt.name == chosenGender) {
      setChosenGender(null);
    } else {
      setChosenGender(evt.name);
    }
  };
  const [chosenSizes, setChosenSizes] = useState([]);
  const [highlightedSize, setHighlightedSize] = useState(null);
  const highlightSize = (evt) => {
    if (!chosenSizes.includes(evt.name)) {
      setHighlightedSize(evt.name);
    }
  };
  const unhighlightSize = (evt) => {
    if (!chosenSizes.includes(evt.name)) {
      setHighlightedSize(null);
    }
  };
  const chooseSize = (evt) => {
    let sizes = [...chosenSizes];
    if (sizes.includes(evt.name)) {
      const index = sizes.indexOf(evt.name);
      sizes.splice(index, 1);
      setChosenSizes(sizes);
    } else {
      sizes.push(evt.name);
      setChosenSizes(sizes);
    }
  };
  const [chosenAges, setChosenAges] = useState([]);
  const [highlightedAge, setHighlightedAge] = useState(null);
  const highlightAge = (evt) => {
    if (!chosenAges.includes(evt.name)) {
      setHighlightedAge(evt.name);
    }
  };
  const unhighlightAge = (evt) => {
    if (!chosenAges.includes(evt.name)) {
      setHighlightedAge(null);
    }
  };
  const chooseAge = (evt) => {
    let ages = [...chosenAges];
    if (ages.includes(evt.name)) {
      const index = ages.indexOf(evt.name);
      ages.splice(index, 1);
      setChosenAges(ages);
    } else {
      ages.push(evt.name);
      setChosenAges(ages);
    }
  };
  const [chosenLocs, setChosenLocs] = useState([]);
  const [highlightedLoc, setHighlightedLoc] = useState(null);
  const highlightLoc = (evt) => {
    if (!chosenLocs.includes(evt.name)) {
      setHighlightedLoc(evt.name);
    }
  };
  const unhighlightLoc = (evt) => {
    if (!chosenLocs.includes(evt.name)) {
      setHighlightedLoc(null);
    }
  };
  const chooseLoc = (evt) => {
    let locs = [...chosenLocs];
    if (locs.includes(evt.name)) {
      const index = locs.indexOf(evt.name);
      locs.splice(index, 1);
      setChosenLocs(locs);
    } else {
      locs.push(evt.name);
      setChosenLocs(locs);
    }
  };
  const [chosenSkintones, setChosenSkintones] = useState([]);
  const [highlightedSkintone, setHighlightedSkintone] = useState(null);
  const highlightSkintone = (evt) => {
    if (!chosenSkintones.includes(evt.name)) {
      setHighlightedSkintone(evt.name);
    }
  };
  const unhighlightSkintone = (evt) => {
    if (!chosenSkintones.includes(evt.name)) {
      setHighlightedSkintone(null);
    }
  };
  const chooseSkintone = (evt) => {
    let skintones = [...chosenSkintones];
    if (skintones.includes(evt.name)) {
      const index = skintones.indexOf(evt.name);
      skintones.splice(index, 1);
      setChosenSkintones(skintones);
    } else {
      skintones.push(evt.name);
      setChosenSkintones(skintones);
    }
  };
  const [chosenHairs, setChosenHairs] = useState([]);
  const [highlightedHair, setHighlightedHair] = useState(null);
  const highlightHair = (evt) => {
    if (!chosenHairs.includes(evt.name)) {
      setHighlightedHair(evt.name);
    }
  };
  const unhighlightHair = (evt) => {
    if (!chosenHairs.includes(evt.name)) {
      setHighlightedHair(null);
    }
  };
  const chooseHair = (evt) => {
    let hairs = [...chosenHairs];
    if (hairs.includes(evt.name)) {
      const index = hairs.indexOf(evt.name);
      hairs.splice(index, 1);
      setChosenHairs(hairs);
    } else {
      hairs.push(evt.name);
      setChosenHairs(hairs);
    }
  };

  const sizeToInt = (size) => {
    const sizes = ["XS", "S", "M", "L", "XL"];
    return sizes.indexOf(size);
  };
  const getDrilldownTitle = () => {
    let title = "";
    if (chosenGender == "Female") {
      title = title + "Women";
    } else if (chosenGender == "Male") {
      title = title + "Men";
    } else if (chosenGender == "Non-Binary") {
      title = title + "Non-Binary Customers";
    } else {
      title = title + "Customers";
    }
    if (chosenAges.length != 0) {
      title = title + " aged " + chosenAges.toSorted().join(", ");
    }
    if (chosenSizes.length != 0) {
      title = title + ", size " + chosenSizes.toSorted((a, b) => (sizeToInt(a)-sizeToInt(b))).join(", ");
    }
    if (chosenHairs.length != 0) {
      title = title + " with " + chosenHairs.join(", ") + " hair";
    }
    if (chosenSkintones.length != 0) {
      if (chosenHairs.length != 0) {
        title = title + " and a " + chosenSkintones.join(", ") + " skin tone";
      } else {
        title = title + " with a " + chosenSkintones.join(", ") + " skin tone";
      }
    }
    if (chosenLocs.length != 0) {
      title = title + " from " + chosenLocs.join(", ");
    }
    if (title == "Customers") {
      title = "All Customers";
    }
    return title;
  };

  const [topCategories, setTopCategories] = useState([]);
  const [topBrands, setTopBrands] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const loadDrilldown = async () => {
    const queryParams = new URLSearchParams();
    queryParams.append("demo", "true");
    for (let age of chosenAges) {
      queryParams.append("age", age);
    }
    for (let size of chosenSizes) {
      queryParams.append("size", size);
    }
    if (chosenGender != null) {
      queryParams.append("gender", chosenGender);
    }
    for (let skintone of chosenSkintones) {
      queryParams.append("skintone", skintone);
    }
    for (let hair of chosenHairs) {
      queryParams.append("hair", hair);
    }
    for (let loc of chosenLocs) {
      queryParams.append("loc", loc);
    }
    const response = await fetch(process.env.REACT_APP_API_URL+"/v2/dashboard/" + brandId + "/users/likelihood?" + queryParams, {
      method: 'GET',
      credentials: 'include',
    });
    if (response.ok) {
      const json = await response.json();
      setTopCategories(json.behavior.category);
      setTopBrands(json.behavior.brand);
      setTopProducts(json.topProducts);
    }
  };
  useEffect(() => {
    loadDrilldown();
  }, [chosenAges, chosenSizes, chosenGender, chosenLocs, chosenSkintones, chosenHairs]);

  const goToCustomersPage = () => {
    navigate("/dashboard/" + brandId + "/customers/", {state: {ages: chosenAges, sizes: chosenSizes, gender: chosenGender, locs: chosenLocs, skintones: chosenSkintones, hairs: chosenHairs}});
  };
  const goToRulesPage = () => {
    navigate("/dashboard/" + brandId + "/rules/", {state: {ages: chosenAges, sizes: chosenSizes, gender: chosenGender, locs: chosenLocs, skintones: chosenSkintones, hairs: chosenHairs}});
  };

  return (
    <div className="WishlistContainerVertical" >
      <div className="WishlistHeader" >
      <div className="WishlistHeaderMaxWidth" >
        <div className="WishlistLogo" >
          {"spree"}
        </div>
        <Link className="DashboardHeaderButton" to={"../analytics/"} relative="path" >
          {"Analytics"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../customers/"} relative="path" >
          {"Customers"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../products/"} relative="path" >
          {"Products"}
        </Link>
        <Link className="DashboardHeaderButton" to={"../rules/"} relative="path" >
          {"Rules"}
        </Link>
      </div>
      </div>
      <div className="WishlistContainerHorizontal" >
      <div className="WishlistContainerMaxWidth" >
        <div className="AnalyticsDemographicsContainer" >
          <div className="AnalyticsDemographicsTitle" >
            {"Customer Demographics Breakdown"}
          </div>
          <div className="AnalyticsDemographicsCharts" >
            <div className="AnalyticsChart" >
              <BarChart width={300} height={200} data={ageData} >
                <XAxis dataKey={"name"} style={{fontFamily: "Baskerville"}} />
                <YAxis tick={false} />
                <Bar dataKey={"value"} fill={"#d8bdff"} onMouseEnter={highlightAge} onMouseLeave={unhighlightAge} onClick={chooseAge} isAnimationActive={false} >
                  {ageData.map((entry, index) => (
                    <Cell key={"cell-" + index} stroke={(entry.name == highlightedAge || chosenAges.includes(entry.name)) ? "black" : null} />
                  ))}
                </Bar>
              </BarChart>
              <div className="AnalyticsChartTitle" >
                {"Age"}
              </div>
            </div>
            <div className="AnalyticsChart" >
              <PieChart width={300} height={200} >
                <Pie data={genderData} dataKey={"value"} nameKey={"name"} fill={"gray"} onMouseEnter={highlightGender} onMouseLeave={unhighlightGender} onClick={chooseGender} isAnimationActive={false} >
                  <LabelList valueAccessor={(item) => (item.value != 0) ? item.name : ""} position={"outside"} fill={"gray"} stroke={null} offset={10} style={{fontFamily: "Baskerville"}} />
                  {genderData.map((entry, index) => (
                    <Cell key={"cell-" + index} fill={entry.color} stroke={(entry.name == highlightedGender || entry.name == chosenGender) ? "black" : null} style={{outline: 'none'}} />
                  ))}
                </Pie>
              </PieChart>
              <div className="AnalyticsChartTitle" >
                {"Gender"}
              </div>
            </div>
            <div className="AnalyticsChart" >
              <PieChart width={300} height={200} >
                <Pie data={sizeData} dataKey={"value"} nameKey={"name"} fill={"gray"} onMouseEnter={highlightSize} onMouseLeave={unhighlightSize} onClick={chooseSize} isAnimationActive={false} >
                  <LabelList valueAccessor={(item) => (item.value != 0) ? item.name : ""} position={"outside"} fill={"gray"} stroke={null} offset={10} style={{fontFamily: "Baskerville"}} />
                  {sizeData.map((entry, index) => (
                    <Cell key={"cell-" + index} fill={entry.color} stroke={(entry.name == highlightedSize || chosenSizes.includes(entry.name)) ? "black" : null} style={{outline: 'none'}} />
                  ))}
                </Pie>
              </PieChart>
              <div className="AnalyticsChartTitle" >
                {"Size"}
              </div>
            </div>
          </div>
          <div className="AnalyticsDemographicsCharts" >
            <div className="AnalyticsChart" >
              <BarChart width={300} height={200} data={locationData} margin={{bottom: 30}} >
                <XAxis dataKey={"name"} style={{fontFamily: "Baskerville", fontSize: 12}} interval={0} scaleToFit={true} angle={-40} textAnchor={"end"} />
                <YAxis tick={false} />
                <Bar dataKey={"value"} fill={"#d8bdff"} onMouseEnter={highlightLoc} onMouseLeave={unhighlightLoc} onClick={chooseLoc} isAnimationActive={false} maxBarSize={30} >
                  {locationData.map((entry, index) => (
                    <Cell key={"cell-" + index} stroke={(entry.name == highlightedLoc || chosenLocs.includes(entry.name)) ? "black" : null} />
                  ))}
                </Bar>
              </BarChart>
              <div className="AnalyticsChartTitle" >
                {"Location"}
              </div>
            </div>
            <div className="AnalyticsChart" >
              <PieChart width={300} height={200} >
                <Pie data={skintoneData} dataKey={"value"} nameKey={"name"} fill={"gray"} onMouseEnter={highlightSkintone} onMouseLeave={unhighlightSkintone} onClick={chooseSkintone} isAnimationActive={false} >
                  <LabelList valueAccessor={(item) => (item.value != 0) ? item.name : ""} position={"outside"} fill={"gray"} stroke={null} offset={10} style={{fontFamily: "Baskerville"}} />
                  {skintoneData.map((entry, index) => (
                    <Cell key={"cell-" + index} fill={entry.color} stroke={(entry.name == highlightedSkintone || chosenSkintones.includes(entry.name)) ? "black" : null} style={{outline: 'none'}} />
                  ))}
                </Pie>
              </PieChart>
              <div className="AnalyticsChartTitle" >
                {"Skin Tone"}
              </div>
            </div>
            <div className="AnalyticsChart" >
              <PieChart width={300} height={200} >
                <Pie data={hairData} dataKey={"value"} nameKey={"name"} fill={"gray"} onMouseEnter={highlightHair} onMouseLeave={unhighlightHair} onClick={chooseHair} isAnimationActive={false} >
                  <LabelList valueAccessor={(item) => (item.value != 0) ? item.name : ""} position={"outside"} fill={"gray"} stroke={null} offset={10} style={{fontFamily: "Baskerville"}} />
                  {hairData.map((entry, index) => (
                    <Cell key={"cell-" + index} fill={entry.color} stroke={(entry.name == highlightedHair || chosenHairs.includes(entry.name)) ? "black" : null} style={{outline: 'none'}} />
                  ))}
                </Pie>
              </PieChart>
              <div className="AnalyticsChartTitle" >
                {"Hair Color"}
              </div>
            </div>
          </div>
        </div>
        <div className="AnalyticsDrilldownContainer" >
          <div className="AnalyticsDrilldownHeader" >
            <div className="AnalyticsDrilldownTitle" >
              {"Drilldown: " + getDrilldownTitle()}
            </div>
            <div className="AnalyticsDrilldownHeaderText" onClick={goToCustomersPage} >
              {"Explore Individual Customers"}
              <IoArrowForward className="AnalyticsForwardArrow" />
            </div>
          </div>
          <div className="AnalyticsDrilldownBody" >
            <div className="AnalyticsDrilldownTopAttributes" >
              <div className="AnalyticsDrilldownAttribute" >
                {"Top Categories: " + topCategories.join(", ")}
              </div>
              <div className="AnalyticsDrilldownAttribute" >
                {"Other Brands: " + topBrands.join(", ")}
              </div>
            </div>
            <div className="AnalyticsDrilldownProducts" >
              <div className="AnalyticsDrilldownProductsHeader" >
              <div className="AnalyticsDrilldownProductsTitle" >
                {"Products these Customers are Seeing"}
              </div>
              <div className="AnalyticsDrilldownProductsCustomizeButton" onClick={goToRulesPage} >
                {"Customize"}
                <IoArrowForward className="AnalyticsForwardArrow" />
              </div>
              </div>
              <div className="AnalyticsProductRow" >
                {topProducts.slice(0, 5).map(item => (
                  <div className="AnalyticsProduct" >
                    <img className="AnalyticsProductImage" src={item.imageEndpoint} />
                  </div>))}
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Analytics;
